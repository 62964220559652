export const environment = {
  production: false,
  build_env: "tst",
  redirectUri: "https://live-tst.duke-energy.app/",
  esriPortalUrl: "https://agsstagingportal.duke-energy.com/portal",
  msalConfig: {
    auth: {
      clientId: "1ea96142-488b-4368-8034-bd9c171ba343",
      authority: "https://login.microsoftonline.com/2ede383a-7e1f-4357-a846-85886b2c0c4d"
    }
  },
  apiConfigs: {
    graphApi: {
      scopes: ["user.read"],
      uri: "https://graph.microsoft.com/v1.0/me"
    },
    liveApi: {
      scopes: ["api://1ea96142-488b-4368-8034-bd9c171ba343/Live.API"],
      uri: "https://live-data-application-api-tst.duke-energy.app/"
    }
  }
};
